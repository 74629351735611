$edu-blue: #0094d6;
$edu-primary-blue: #0c425c;
$edu-purple: #C99AD0;
$edu-red: #FF5C5C;
$edu-orange: #FFB94D;
$edu-primary-yellow: #FAEA77;
$edu-primary-green: #28E890;
$edu-green: #44BF8A;
$edu-white: #FFFFFF;
$edu-black: #000000;

/* variants 25% */
$edu-orange-25: rgba(255, 185, 77, 0.25);
$edu-green-25: rgba(68, 191, 138, 0.25);
$edu-yellow-25: rgba(250, 234, 119, 0.25);
$edu-blue-25: rgb(0, 148, 214, 0.25);
$edu-purple-25: rgb(201, 154, 208, 0.25);
$edu-red-25: rgb(255, 92, 92, 0.25);
